exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alternative-investments-investments-js": () => import("./../../../src/pages/alternative-investments/investments.js" /* webpackChunkName: "component---src-pages-alternative-investments-investments-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ira-college-jsx": () => import("./../../../src/pages/ira-college.jsx" /* webpackChunkName: "component---src-pages-ira-college-jsx" */),
  "component---src-pages-network-apply-for-business-jsx": () => import("./../../../src/pages/network/apply-for-business.jsx" /* webpackChunkName: "component---src-pages-network-apply-for-business-jsx" */),
  "component---src-pages-network-professional-network-jsx": () => import("./../../../src/pages/network/professional-network.jsx" /* webpackChunkName: "component---src-pages-network-professional-network-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-resources-blog-jsx": () => import("./../../../src/pages/resources/blog.jsx" /* webpackChunkName: "component---src-pages-resources-blog-jsx" */),
  "component---src-pages-resources-calculators-jsx": () => import("./../../../src/pages/resources/calculators.jsx" /* webpackChunkName: "component---src-pages-resources-calculators-jsx" */),
  "component---src-pages-university-advantages-of-the-sepirak-record-keeping-system-jsx": () => import("./../../../src/pages/university/advantages-of-the-sepirak-record-keeping-system.jsx" /* webpackChunkName: "component---src-pages-university-advantages-of-the-sepirak-record-keeping-system-jsx" */),
  "component---src-pages-university-benefits-of-a-solo-401-k-jsx": () => import("./../../../src/pages/university/benefits-of-a-solo-401k.jsx" /* webpackChunkName: "component---src-pages-university-benefits-of-a-solo-401-k-jsx" */),
  "component---src-pages-university-what-is-a-solo-401-k-jsx": () => import("./../../../src/pages/university/what-is-a-solo-401k.jsx" /* webpackChunkName: "component---src-pages-university-what-is-a-solo-401-k-jsx" */),
  "component---src-templates-blog-article-jsx": () => import("./../../../src/templates/blog-article.jsx" /* webpackChunkName: "component---src-templates-blog-article-jsx" */),
  "component---src-templates-calculator-jsx": () => import("./../../../src/templates/calculator.jsx" /* webpackChunkName: "component---src-templates-calculator-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-retirement-jsx": () => import("./../../../src/templates/retirement.jsx" /* webpackChunkName: "component---src-templates-retirement-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

