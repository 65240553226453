import './src/styles/global.css';
import React from 'react';
import { Helmet } from 'react-helmet';

export const wrapRootElement = ({ element }) => (
    <>
        <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/23684445.js" />
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3H8VT1HQL" />
            <script>
                {` window.dataLayer = window.dataLayer || []
                  function gtag() {
                    dataLayer.push(arguments)
                  }
                  gtag('js', new Date())
                  gtag('config', 'G-Y3H8VT1HQL')`}
            </script>
        </Helmet>
        {element}
    </>
);
